import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import CSSModules from 'react-css-modules';
import { Link } from 'gatsby';

import { constructLink } from '../../../../helper';
import styles from './styles.module.css';
import '../../../../carousel.css';
import Image from '../../../Image';

class CarouselLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const index = (props.section.data.length - 1);

    let crop = true;
    if (props.section && index !== undefined && props.section.data[index].crop === false) ({ crop } = props.section.data[index]);

    this.state = {
      section: props.section,
      index,
      crop,
    };

  }

  render() {

    const colorBlack = {
      color: '#000000',
    };

    const cards = [];

    this.state.section.data.map((item, index) => {

      if (item.type === 'IMAGES/IMAGE') {

        const card = (
          <div
            key={`${this.state.section._id}_${this.state.section.data[index].data.src}_${index}`}
            className={`${styles.carouselWrapper}`}
          >
            <div className={`${styles.carouselImageWrapper}`}>
              <Image
                id={this.state.section.data[index].data.id}
                url={this.state.section.data[index].data.src}
                alt={this.state.section.data[index].data.alt}
                imageClass={`carouselImg${this.state.crop === false ? 'NoCrop' : ''}`}
                images={this.props.images}
              />
              {
                (
                  this.state.section.data[this.state.section.data.length - 1].alt === true ||
                  this.state.section.data[this.state.section.data.length - 1].description === true
                ) &&
                (item.data.alt || item.data.description) &&
                (
                  <div className={`${styles.imageTextWrapper}`} style={this.props.boxStyle}>
                    {
                      this.state.section.data[this.state.section.data.length - 1].alt === true &&
                      (
                        <div className={`${styles.imageAlt}`}>
                          <h2 style={colorBlack}>
                            {
                              item.data.alt
                            }
                          </h2>
                        </div>
                      )
                    }
                    {
                      this.state.section.data[this.state.section.data.length - 1].description === true &&
                      (
                        <div className={`${styles.imageText}`}>
                          <span style={colorBlack}>
                            {
                              item.data.description
                            }
                          </span>
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
          </div>
        );

        if (item.data.link && (item.data.link.src || item.data.link.type)) {

          const { link, type } = constructLink(
            item.data.link.type ? item.data.link : item.data.link.src,
            this.props.pagePathList,
            this.props.articlePathList,
          );

          const isLink = link !== undefined;
          const isLocal = isLink && type !== 'EXTERNAL';
          let tab;
          if (!isLocal) tab = link && link.startsWith('http') ? '_blank' : '_self';
          else {

            if (typeof item.data.link.type) tab = item.data.link.openLinkInNewTab ? '_blank' : '_self';
            else tab = item.data.link.newTab && item.data.link.src.startsWith('http') ? '_blank' : '_self';

          }


          let cardLinked;
          if (type !== 'EXTERNAL' && isLink && tab !== '_blank') {

            cardLinked = (
              <Link to={link} key={`${this.props.section._id}_CarouselLink_${index}`}>
                { card }
              </Link>
            );

          } else if (isLink && (!isLocal || tab === '_blank')) {

            cardLinked = (
              <a
                target={tab ? '_blank' : '_self'}
                href={link}
                key={`${this.props.section._id}_CarouselLink_${index}`}
                rel={tab ? 'noopener noreferrer' : undefined}
              >
                { card }
              </a>
            );

          } else cardLinked = card;

          cards.push(cardLinked);

        } else {

          cards.push(card);

        }

      }

      return null;

    });

    let { arrows } = this.state.section.data[this.state.index];
    if (typeof document !== 'undefined') {

      if (document.documentElement.clientWidth <= 576) {

        arrows = false;

      }

    }

    return (
      <div className={`${this.props.full ? '' : 'container'} ${styles[`carouselContainer${this.props.full}` ]}` }>
        <div className={`row ${this.props.full ? styles.noMargin : ''}`}>
          <div className={`col-12 ${styles.carouselCol}`}>
            <Carousel
              showThumbs={false}
              showArrows={arrows}
              showIndicators={this.state.section.data[this.state.index].indicators}
              showStatus={false}
              autoPlay={this.state.section.data[this.state.index].autoplay}
              infiniteLoop={this.state.section.data[this.state.index].infinite}
              interval={this.state.section.data[this.state.index].interval}
              axis={this.state.section.data[this.state.index].transition}
            >
              { cards }
            </Carousel>
          </div>
        </div>
      </div>
    );

  }

}

CarouselLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  full: PropTypes.string,
};

export default CSSModules(CarouselLayout, styles, { allowMultiple: true });
